import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './components/auth/ProtectedRoute';
import Navbar from './components/Navbar';
import ErrorBoundary from './components/ErrorBoundary';
import LoadingSpinner from './components/LoadingSpinner';

// Lazy load components
const CategorySection = React.lazy(() => import('./components/CategorySection'));
const FeaturedListings = React.lazy(() => import('./components/FeaturedListings'));
const LoginForm = React.lazy(() => import('./components/auth/LoginForm'));
const RegisterForm = React.lazy(() => import('./components/auth/RegisterForm'));
const Dashboard = React.lazy(() => import('./components/Dashboard'));
const Profile = React.lazy(() => import('./components/Profile'));
const Bookings = React.lazy(() => import('./components/Bookings'));

const App: React.FC = () => {
  return (
    <Router>
      <ErrorBoundary>
        <AuthProvider>
          <div className="min-h-screen bg-gray-50">
            <Routes>
              {/* Public routes */}
              <Route path="/login" element={
                <Suspense fallback={<LoadingSpinner />}>
                  <LoginForm />
                </Suspense>
              } />
              <Route path="/register" element={
                <Suspense fallback={<LoadingSpinner />}>
                  <RegisterForm />
                </Suspense>
              } />
              
              {/* Protected routes */}
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <Suspense fallback={<LoadingSpinner />}>
                      <Dashboard />
                    </Suspense>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <Suspense fallback={<LoadingSpinner />}>
                      <Profile />
                    </Suspense>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/bookings"
                element={
                  <ProtectedRoute>
                    <Suspense fallback={<LoadingSpinner />}>
                      <Bookings />
                    </Suspense>
                  </ProtectedRoute>
                }
              />

              {/* Home route */}
              <Route
                path="/"
                element={
                  <>
                    <Navbar />
                    <main className="pt-16">
                      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                        <div className="text-center">
                          <h1 className="text-4xl font-bold text-gray-900 sm:text-5xl md:text-6xl">
                            Trouvez Votre
                            <span className="text-indigo-600"> Expérience</span>
                          </h1>
                          <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                            Découvrez les meilleurs hébergements, services de transport et expériences à travers la France.
                          </p>
                        </div>
                      </div>
                      <Suspense fallback={<LoadingSpinner />}>
                        <CategorySection />
                        <FeaturedListings />
                      </Suspense>
                    </main>
                  </>
                }
              />

              {/* Catch all route */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </div>
        </AuthProvider>
      </ErrorBoundary>
    </Router>
  );
};

export default App;