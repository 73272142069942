import React, { useState, useCallback, useRef } from 'react';
import { Search as SearchIcon, X } from 'lucide-react';
import { useSearch } from '../../hooks/useSearch';
import { debounce } from '../../utils/debounce';

export default function SearchInput() {
  const [query, setQuery] = useState('');
  const { updateFilters } = useSearch();
  
  // Mémoriser la fonction de recherche avec useCallback et useRef
  const debouncedSearch = useRef(
    debounce((searchQuery: string) => {
      updateFilters({ location: searchQuery });
    }, 300)
  ).current;

  const handleQueryChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQuery(value);
    debouncedSearch(value);
  }, [debouncedSearch]);

  const handleClear = useCallback(() => {
    setQuery('');
    updateFilters({ location: '' });
  }, [updateFilters]);

  return (
    <div className="relative max-w-lg w-full">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <SearchIcon className="h-5 w-5 text-gray-400" />
      </div>
      <input
        type="text"
        value={query}
        onChange={handleQueryChange}
        className="block w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        placeholder="Rechercher des lieux, services ou équipements..."
      />
      {query && (
        <button
          onClick={handleClear}
          className="absolute inset-y-0 right-0 pr-3 flex items-center"
        >
          <X className="h-5 w-5 text-gray-400 hover:text-gray-600" />
        </button>
      )}
    </div>
  );
}