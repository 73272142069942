import { useState, useCallback, useMemo } from 'react';
import { SearchFilters, SearchResult } from '../types/search';

const ITEMS_PER_PAGE = 20;

export function useSearch() {
  const [results, setResults] = useState<SearchResult[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<SearchFilters>({
    type: [],
    priceRange: [0, 1000],
    rating: null,
    location: '',
    dates: undefined,
    amenities: [],
    instantBook: false,
    superHost: false,
  });

  const searchListings = useCallback(async (filters: SearchFilters, page: number) => {
    try {
      setLoading(true);
      setError(null);

      const response = await fetch(`/api/search?page=${page}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(filters),
      });

      if (!response.ok) throw new Error('Search failed');

      const data = await response.json();
      setResults(prev => page === 1 ? data : [...prev, ...data]);
    } catch (err) {
      setError('Failed to fetch results');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const filteredResults = useMemo(() => {
    return results.filter(result => {
      if (filters.type.length && !filters.type.includes(result.type)) return false;
      if (result.price < filters.priceRange[0] || result.price > filters.priceRange[1]) return false;
      if (filters.rating && result.rating < filters.rating) return false;
      if (filters.location && !result.location.toLowerCase().includes(filters.location.toLowerCase())) return false;
      if (filters.amenities.length && !filters.amenities.every(a => result.amenities.includes(a))) return false;
      if (filters.instantBook && !result.instantBook) return false;
      if (filters.superHost && !result.superHost) return false;
      return true;
    });
  }, [results, filters]);

  const updateFilters = useCallback((newFilters: Partial<SearchFilters>) => {
    setFilters(prev => {
      const updated = { ...prev, ...newFilters };
      setPage(1);
      searchListings(updated, 1);
      return updated;
    });
  }, [searchListings]);

  const loadMore = useCallback(() => {
    const nextPage = page + 1;
    setPage(nextPage);
    searchListings(filters, nextPage);
  }, [page, filters, searchListings]);

  return {
    results: filteredResults,
    loading,
    error,
    filters,
    updateFilters,
    loadMore,
    hasMore: results.length >= page * ITEMS_PER_PAGE,
  };
}